import { MAIN_URL } from "../utils/constants";

class MainApi {
  constructor({ baseUrl }) {
    this._BASE_URL = baseUrl;
  }
  
    _checkResponse(res) {
      if (res.ok) {
        return res.json();
      } else {
        const statusCode = res.status;
        return res.json().then((res) => {
          return Promise.reject({
            statusCode: statusCode,
            message: res.msg,
            detail: res.detail,
          });
        });
      }
    }
  
    getAllCategories() {
        return fetch(`${MAIN_URL}/categories/get-all/?start=0&limit=10`, {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }).then(this._checkResponse);
      }

      getAllProductByCategory({ categoryId }) {
        return fetch(`${MAIN_URL}/products/no-auth/get-by-category/${categoryId}`, {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }).then(this._checkResponse);
      }

      getProductCart({ cartId }) {
        return fetch(`${MAIN_URL}/products/no-auth/get-by-ids/?ids=${cartId}`, {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }).then(this._checkResponse);
      }
  }
  
  const mainApi = new MainApi({
    baseUrl: MAIN_URL,
  });
  
  export default mainApi;