import { motion } from 'framer-motion';
import { BellIcon } from '../../assets/icons/icons';
import './ServiceBtn.css';
import { useContext } from 'react';
import { CartContext } from '../../assets/contexts/cartContext';

const bellMotion = {
  upper: { y: '-76px' },
  base: { y: '0px' },
};

function ServiceBtn({ isOpen, onOpen }) {
  const { cart } = useContext(CartContext);

  return (
    <motion.button
      className={`bell-button ${isOpen ? 'bell-button_disabled' : ''}`}
      type="button"
      animate={cart.length > 0 ? 'upper' : 'base'}
      variants={bellMotion}
      onClick={onOpen}
    >
      <div className='bell-button__box'>
        <BellIcon />
      </div>
    </motion.button>
  );
}

export default ServiceBtn;
