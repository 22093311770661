import ophisiant from '../images/ophisiant.svg'
import kalian from '../images/kalian-logo.svg'
import beelt from '../images/beelt-logo.svg'

export const MAIN_URL = 'https://adm.tng-typography.ru/api'
export const PHOTO_URL = 'https://adm.tng-typography.ru/uploads'
export const MENU_URL = 'https://staff.tng-typography.ru/api'

export const CATALOG = [
    {
        id: 1,
        title: 'Кальяны',
        translit_name: 'kalian'
    },
    {
        id: 2,
        title: 'Холодные напитки',
        translit_name: 'holnapitok'
    },
    {
        id: 3,
        title: 'Горячие напитки',
        translit_name: 'gornapitok'
    },
    {
        id: 4,
        title: 'Алкоголь',
        translit_name: 'alko'
    },
    {
        id: 5,
        title: 'Закуски',
        translit_name: 'zakuski'
    },
]

export const WAITER_CATALOG_BUTTON = {
    title: 'Бармен',
    icon: ophisiant,
    type: 'waiter'
};
export const MASTER_CATALOG_BUTTON = {
    title: 'Кальянный мастер',
    icon: kalian,
    type: 'master'
};
export const BILL_CATALOG_BUTTON = {
    title: 'Счет',
    icon: beelt,
    type: 'bilt'
};
export const CATALOG_BUTTON = [WAITER_CATALOG_BUTTON, MASTER_CATALOG_BUTTON, BILL_CATALOG_BUTTON];

export const HOOKAH_REQUEST_TYPE = 'hookah';
export const BARMAN_REQUEST_TYPE = 'barman';
export const CHECK_REQUEST_TYPE = 'check';

export const CATALOG_MAIN_LINK = 'catalog'
export const CATALOG_ITEM_LINK = 'item'
export const CART_MAIN_LINK = 'cart'

export const CURRENCY_MAP = {
    'USD': '$',  // United States Dollar
    'EUR': '€',  // Euro
    'JPY': '¥',  // Japanese Yen
    'GBP': '£',  // British Pound
    'AUD': 'A$', // Australian Dollar
    'CAD': 'C$', // Canadian Dollar
    'CHF': 'CHF', // Swiss Franc
    'CNY': '¥',  // Chinese Yuan
    'SEK': 'kr',  // Swedish Krona
    'NZD': 'NZ$', // New Zealand Dollar
    'MXN': '$',  // Mexican Peso
    'SGD': 'S$', // Singapore Dollar
    'HKD': 'HK$', // Hong Kong Dollar
    'NOK': 'kr',  // Norwegian Krone
    'KRW': '₩',  // South Korean Won
    'TRY': '₺',  // Turkish Lira
    'INR': '₹',  // Indian Rupee
    'RUB': '₽',  // Russian Ruble
    'BRL': 'R$', // Brazilian Real
    'ZAR': 'R',  // South African Rand
};

export const productIdStorageKey = 'products_id'
export const historyIdStorageKey = 'history_cart'
export const tableStorageKey = 'table'

export const companyName = 'Tangiers Lounge'