import { useEffect, useState } from 'react';
import './Loading.css';
import logo from '../../assets/images/hat.png';
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"
import { TangLogo } from '../../assets/icons/companyIcons';
import Terms from '../Terms/Terms';

function Loading() {
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate()

    useEffect(() => {
        const totalDuration = 2000; // 5 seconds
        const step = 0.1;
        const intervalDuration = 6; // 50 milliseconds
        const stepsInInterval = (step / 100) * totalDuration / intervalDuration;
        const id = setInterval(() => {
            setProgress(prevProgress => {
                const newProgress = prevProgress + stepsInInterval;
                if (newProgress >= 100) {
                    clearInterval(id);
                    return 100;
                }
                return newProgress;
            });
        }, intervalDuration);

        setTimeout(() => {
            navigate("/catalog")
        }, totalDuration);

        return () => {
            clearInterval(id);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <motion.div
            animate={{ opacity: 0 }}
            initial={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.8 }}
            className='loading'>
            <motion.div
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 1.5, delay: 0.2 }}
            >
                <TangLogo />
            </motion.div>
            <div className='loading__logo-and-progress'>
                <motion.img
                    animate={{ y: 0 }}
                    initial={{ y: 150 }}
                    transition={{ duration: 1.0 }}
                    src={logo} alt='' className='loading__logo'>
                </motion.img>
                <div className='loading__shadow'></div>
                <motion.p
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 1.5, delay: 0.2 }}
                    className='loading__title'>MENU</motion.p>
                <motion.div
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 1.5, delay: 0.2 }}
                    className='loading__progress-box'>
                    <div
                        style={{
                            width: `${progress}%`,
                        }}
                        className='loading__progress'
                    ></div>
                </motion.div>
            </div>
            <div className='loading__line__box'>
                <Terms />
            </div>

        </motion.div>
    );
}

export default Loading