import { useEffect } from 'react';
import { CATALOG_BUTTON } from '../../assets/utils/constants';
import './ServiceMenu.css';
import { AnimatePresence, motion } from "framer-motion"
import ServiceButtonsBlock from '../ServiceButtonsBlock/ServiceButtonsBlock';

function ServiceMenu({ isOpen, setIsOpen, onOpenConf }) {

    function OpenConfirm(item) {
        onOpenConf(prevState => ({
            ...prevState,
            visible: true,
            type: item.type,
            icon: item.icon
        }))
    }


    return (
        <>
            <div onClick={() => setIsOpen(isOpen => !isOpen)} className={`service-menu__bg ${isOpen ? 'service-menu__bg_open' : ''}`}></div>
            {isOpen ?
                <motion.div
                    animate={{ opacity: 1, y: "0%", delay: 0.8 }}
                    initial={{ opacity: 0, y: '100%' }}
                    transition={{ type: 'spring' }}
                    className="service-menu">
                    <p className='service-menu__title'>Сервис</p>
                    <svg className='service-menu__close' onClick={() => setIsOpen(isOpen => !isOpen)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 6L6 18" stroke="#ABADB7" strokeWidth="1.6" strokeLinecap="round" />
                        <path d="M6 6L18 18" stroke="#ABADB7" strokeWidth="1.6" strokeLinecap="round" />
                    </svg>
                    <ServiceButtonsBlock isOpenConf={isOpen} setIsOpenConf={onOpenConf} />
                </motion.div>
                : null}

        </>
    );
}

export default ServiceMenu;