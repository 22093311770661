import { MENU_URL } from '../utils/constants';

class MenuApi {
  constructor({ baseUrl }) {
    this._BASE_URL = baseUrl;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  sendPublicRequest({ type, table }) {
    return fetch(`${MENU_URL}/public/request`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ type, table }),
    }).then(this._checkResponse);
  }
}

const menuApi = new MenuApi({
  baseUrl: MENU_URL,
});

export default menuApi;
