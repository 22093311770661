import { useEffect, useState } from 'react';
import './HeaderSlideMenu.css';
import { motion } from "framer-motion"
import mainApi from '../../../assets/api/MainApi';
import { Link } from 'react-router-dom';
import { TangLogo } from '../../../assets/icons/companyIcons';

function HeaderSlideMenu({ isOpen, setIsOpen }) {
    const [catalog, setCatalog] = useState([])

    function GetCategory() {
        // setIsPreloaderVisible(true);
        mainApi
            .getAllCategories()
            .then((res) => {
                setCatalog(res)
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                // setIsPreloaderVisible(false);
            });
    }

    useEffect(() => {
        GetCategory()
    }, [])

    return (
        <>
            <div onClick={() => setIsOpen(isOpen => !isOpen)} className={`header-menu__bg ${isOpen ? 'header-menu__bg_open' : ''}`}></div>
            {isOpen ?
                <motion.div
                    animate={{ opacity: 1, y: "9%" }}
                    initial={{ opacity: 0, y: "-100%" }}
                    transition={{ type: 'spring', stiffness: 40 }}
                    className="header-slide-menu">
                    <TangLogo />
                    <div className='catalog__title-menu__box'>
                        {catalog.map((item, i) => {
                            return (
                                <Link to={`/catalog/${item.id}`} onClick={() => setIsOpen(false)} key={i} className='catalog__title-menu'>{item.title}</Link>
                            )
                        })
                        }
                    </div>
                </motion.div>
                : null}
        </>
    );
}

export default HeaderSlideMenu;