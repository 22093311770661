import { useEffect, useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import './Header.css'
import { motion } from "framer-motion"
import HeaderSlideMenu from "./HeaderSlideMenu/HeaderSlideMenu";
import { TangLogoHeader } from "../../assets/icons/companyIcons";

function Header() {
    const { pathname } = useLocation();
    const [isOpenHeader, setIsOpenHeader] = useState(false);
    const [isHiddenAll, setIsHiddenAll] = useState(true);
    const [isHiddenPart, setIsHiddenPart] = useState(true)

    useEffect(() => {
        setIsHiddenAll(pathname === '/')
        setIsHiddenPart(pathname === '/catalog')
    }, [pathname])

    // useEffect(() => {
    //     if (!isOpenHeader) { document.body.style.overflow = 'initial'; }
    //     else { document.body.style.overflow = 'hidden'; }
    // }, [isOpenHeader]);
    const [scrollWindowPosition, setScrollWindowPosition] = useState(0);
    const [popupScrollPosition, setPopupScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;

        setScrollWindowPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isOpenHeader) {
            document.body.style.overflow = "hidden";
            document.body.style.top = `-${scrollWindowPosition}px`;
            setPopupScrollPosition(scrollWindowPosition);
            setTimeout(() => {
                document.body.style.position = "fixed";
                document.body.style.width = "100%";
                document.body.style.height = "100%";
            }, 10);

            console.log(window.pageYOffset);
        } else {
            document.body.style.overflow = "unset";
            document.body.style.position = "unset";
            document.body.style.width = "unset";
            document.body.style.height = "unset";
            document.body.style.top = `unset`;
            console.log(popupScrollPosition);
            window.scrollTo(0, popupScrollPosition);
            setScrollWindowPosition(popupScrollPosition);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenHeader]);

    const [realTable, setRealTable] = useState(null);
    let realTableLocal = JSON.parse(localStorage.getItem('table'));
    useEffect(() => {
        // let realTableLocal = JSON.parse(localStorage.getItem('table'));
        if (!realTableLocal) {
            // localStorage.removeItem('table');
            setRealTable(null);
        } else {
            setRealTable((realTableLocal));
        }
    }, []);

    return (
        <>
            <div className={`header ${isHiddenAll ? 'header_hidden' : ''} ${isHiddenPart ? 'header_no-bg' : ''}`}>
                <div onClick={() => setIsOpenHeader(isOpenHeader => !isOpenHeader)} className={`header__burger-and-rocket-box ${isHiddenPart ? 'header_opacity' : ''}`}>

                    <div className="header__burger-box">
                        <motion.div
                            animate={{
                                rotate: isOpenHeader ? 45 : -180,
                                y: isOpenHeader ? '3px' : '-3px'
                            }}
                            transition={{ type: "spring" }}
                            className="burger-line burger-line_first"></motion.div>
                        {isOpenHeader ? '' :
                            <div className="burger-line burger-line_second"></div>}
                        <motion.div
                            animate={{
                                rotate: isOpenHeader ? -45 : 180,
                                y: isOpenHeader ? '-3px' : '3px'
                            }}
                            transition={{ type: "spring" }}
                            className="burger-line burger-line_third"></motion.div>
                    </div>
                </div>

                <Link onClick={() => setIsOpenHeader(false)} className='header__logo' to={`/catalog`}>
                    <p className='header-title'>
                        <TangLogoHeader />
                    </p>
                </Link>

                <div
                    style={{ opacity: 0 }}
                >
                    <svg className='header__cosmonavt' xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M14.0001 16.1053C15.7441 16.1053 17.158 14.6914 17.158 12.9474C17.158 11.2033 15.7441 9.78949 14.0001 9.78949C12.256 9.78949 10.8422 11.2033 10.8422 12.9474C10.8422 14.6914 12.256 16.1053 14.0001 16.1053Z" stroke="#DEB75D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24Z" stroke="#DEB75D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M20.1406 21.8813C19.9143 21.126 19.4509 20.4636 18.8189 19.9921C18.187 19.5206 17.4201 19.265 16.6316 19.2632H11.3684C10.58 19.265 9.81306 19.5206 9.18112 19.9921C8.54919 20.4636 8.08579 21.126 7.8595 21.8813" stroke="#DEB75D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
            <HeaderSlideMenu isOpen={isOpenHeader} setIsOpen={setIsOpenHeader} />
        </>
    )
}
export default Header