import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import './OrderCard.css';
import { TriangleIcon } from '../../../assets/icons/icons';
import { CartContext } from '../../../assets/contexts/cartContext';
import { productIdStorageKey } from '../../../assets/utils/constants';
import moment from 'moment-timezone';
import 'moment/min/locales';
moment.locale('ru');

function OrderCard({ order }) {
  const { setCart } = useContext(CartContext);
  const [isOpen, setIsOpen] = useState(false);

  function openCard() {
    setIsOpen(true);
  }

  function closeCard() {
    setIsOpen(false);
  }

  function totalPrice() {
    return order.cart_id.reduce((prevVal, item) => {
      const total = item.price * item.count;
      return (prevVal += total);
    }, 0);
  }

  function totalCount() {
    const total = order.cart_id
      .reduce((prevVal, item) => (prevVal += item.count), 0)
      .toString();

    let text;
    switch (true) {
      case total.endsWith('1') && !total.endsWith('11'):
        text = 'позиция';
        break;

      case total.endsWith('2') && !total.endsWith('12'):
      case total.endsWith('3') && !total.endsWith('13'):
      case total.endsWith('4') && !total.endsWith('14'):
        text = 'позиции';
        break;

      default:
        text = 'позиций';
        break;
    }

    return `${total} ${text}`;
  }

  function repeatOrder() {
    const newOrder = order.cart_id;
    setCart(newOrder);
    localStorage.setItem(productIdStorageKey, JSON.stringify(newOrder));
  }

  return (
    <li className="order-card" onClick={!isOpen ? openCard : undefined}>
      <button
        className="order-card__heading"
        type="button"
        onClick={isOpen ? closeCard : undefined}
      >
        <div className="order-card__title-btn">
          <TriangleIcon
            mainClassName={`order-card__arrow ${
              isOpen ? 'order-card__arrow_rotate' : ''
            }`}
            fillClassName="order-card__arrow-fill"
          />
          <p
            className={`order-card__date ${
              isOpen ? 'order-card__date_opened' : ''
            }`}
          >
            {moment(order.order_date).format('D MMMM YYYY • HH:mm')}
          </p>
        </div>

        <Link
          className={`order-card__repeat-btn ${
            isOpen ? 'order-card__repeat-btn_visible' : ''
          }`}
          to="/cart"
          onClick={repeatOrder}
        >
          Повторить заказ
        </Link>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="order-card__items"
            initial={{ opacity: 0, height: 0, y: -20 }}
            animate={{ opacity: 1, height: 'auto', y: 0 }}
            exit={{ opacity: 0, height: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            <ul className="order-card__items-list">
              {order.cart?.map((item) => (
                <li className="order-card__item" key={item.id}>
                  <div className="order-card__item-heading">
                    <p className="order-card__item-title">{item.title}</p>
                    <p className="order-card__item-title">
                      {
                        order.cart_id.find((product) => product.id === item.id)
                          .count
                      }
                    </p>
                  </div>
                  <p className="order-card__item-price">
                    {item.price}{' '}
                    <span className="order-card__item-price_size_small">₽</span>
                  </p>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>

      {isOpen ? (
        <div className="order-card__total">
          <p className="order-card__total-text order-card__total-text_opened">
            Итого:
          </p>
          <p className="order-card__total-price order-card__total-price_opened">
            {totalPrice()}{' '}
            <span className="order-card__total-price_size_medium">₽</span>
          </p>
        </div>
      ) : (
        <div className="order-card__total">
          <p className="order-card__total-text">{totalCount()}</p>
          <p className="order-card__total-price">
            {totalPrice()}{' '}
            <span className="order-card__total-price_size_small">₽</span>
          </p>
        </div>
      )}
    </li>
  );
}

export default OrderCard;
