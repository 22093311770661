import './ServiceButtonsBlock.css';
import { CATALOG_BUTTON } from '../../assets/utils/constants';

function ServiceButtonsBlock({ isOpenConf, setIsOpenConf }) {

    let realTableLocal = JSON.parse(localStorage.getItem('table'));
    function OpenConfirm(item) {
        setIsOpenConf(prevState => ({
            ...prevState,
            visible: true,
            type: item.type,
            icon: item.icon
        }))
    }

    return (
        <div className={`${realTableLocal ? 'catalog__block-button__box' : 'catalog__block-button__box catalog__block-button__box_disable'}`}>
            {CATALOG_BUTTON.map((item, i) => {
                return (
                    <div onClick={() => OpenConfirm(item)} key={i} className='catalog__block-button'>
                        <img alt='' src={item.icon}></img>
                        <p className='catalog__block-button__title'>{item.title}</p>
                    </div>
                )
            })
            }
        </div>
    );
}

export default ServiceButtonsBlock