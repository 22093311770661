import { companyName } from '../../assets/utils/constants';
import './Terms.css';

function Terms({ }) {
    return (
        <div className={'terms__box'}>
            <p>Дизайн и Разработка - <a className='terms__text' href='https://cabahub.com/' target='_blank' rel='noreferrer'>Caba Hub</a> <br />{companyName}. © Все права защищены. 2024</p>
        </div>
    );
}

export default Terms