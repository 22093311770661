import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import Catalog from '../Catalog/Catalog';
import Loading from '../Loading/Loading';
import './App.css';
import { useEffect, useState } from 'react';
import SubcategotyPageTemplate from '../SubcategoryPageTemplate/SubcategoryPageTemplate';
import Cart from '../Cart/Cart';
import { CartContext } from '../../assets/contexts/cartContext';
import OrderHistory from '../OrderHistory/OrderHistory';
import Header from '../Header/Header';
import mainApi from '../../assets/api/MainApi';
import { tab } from "@testing-library/user-event/dist/tab";
import { tableStorageKey } from '../../assets/utils/constants';

function App() {
  const [isLoading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const table = searchParams.get('table');
  const [realTable, setRealTable] = useState(null);

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  const location = useLocation()
  console.log(location.pathname)

  useEffect(() => {
    if (!table && location.pathname === '/') {
      localStorage.removeItem(tableStorageKey);
    }
    if (!isNumeric(table)) {
      // localStorage.removeItem('table');
      setRealTable(null);
    } else {
      localStorage.setItem(tableStorageKey, JSON.stringify(table));
      setRealTable(parseInt(table));
    }
  }, [table]);

  useEffect(() => {
    let realTableLocal = JSON.parse(localStorage.getItem(tableStorageKey));
    if (realTableLocal &&
      isNumeric(realTableLocal) &&
      realTableLocal == 185 ||
      realTableLocal == 777 ||
      realTableLocal == 1 ||
      realTableLocal == 2 ||
      realTableLocal == 3 ||
      realTableLocal == 4 ||
      realTableLocal == 5 ||
      realTableLocal == 6 ||
      realTableLocal == 7 ||
      realTableLocal == 8 ||
      realTableLocal == 9 ||
      realTableLocal == 10 ||
      realTableLocal == 11 ||
      realTableLocal == 12 ||
      realTableLocal == 13 ||
      realTableLocal == 14 ||
      realTableLocal == 15 ||
      realTableLocal == 16 ||
      realTableLocal == 17 ||
      realTableLocal == 18 ||
      realTableLocal == 19 ||
      realTableLocal == 20 ||
      realTableLocal == 21 ||
      realTableLocal == 22 ||
      realTableLocal == 23 ||
      realTableLocal == 24
    ) {
      setRealTable(parseInt(realTableLocal));
    } else {
      localStorage.removeItem(tableStorageKey);
    }
  }, []);

  setTimeout(() => {
    setLoading(true);
  }, 2000);

  const [cart, setCart] = useState([]);
  useEffect(() => {
    // Получаем корзину из localStorage с учетом shop_id
    let cartArray = JSON.parse(localStorage.getItem('products_id')) || [];
    setCart(cartArray);
    console.log(cartArray);
  }, []);

  setTimeout(() => {
    localStorage.removeItem(tableStorageKey)
  }, 7200000);

  const [catalog, setCatalog] = useState([]);

  function getCategory() {
    // setIsPreloaderVisible(true);
    mainApi
      .getAllCategories()
      .then((res) => {
        setCatalog(res);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
    // .finally(() => {
    // setIsPreloaderVisible(false);
    // });
  }

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <div className="app">
      <CartContext.Provider value={{ cart, setCart }}>
        <Header />
        <Routes>
          <Route path="/" element={isLoading ? <Loading /> : null} />
          <Route
            path="/catalog"
            element={
              <Catalog catalog={catalog} />
            }
          />
          <Route path="/catalog/:id" element={<SubcategotyPageTemplate />} />
          {/* <Route path="/cart" element={<Cart />} /> */}
          {/* <Route path="/history" element={<OrderHistory />} /> */}
        </Routes>
      </CartContext.Provider>
    </div>
  );
}

export default App;
