import './Catalog.css';
import { motion } from "framer-motion"
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CATALOG_BUTTON } from '../../assets/utils/constants';
import WaiterConf from '../WaiterConf/WaiterConf';
import { TangLogo } from '../../assets/icons/companyIcons';
import Rules from '../Rules/Rules';
import ServiceButtonsBlock from '../ServiceButtonsBlock/ServiceButtonsBlock';
import Terms from '../Terms/Terms';

function Catalog({ catalog }) {
    const [isRulesOpen, setRulesOpen] = useState(false);
    const [isOpenConf, setIsOpenConf] = useState({
        visible: false,
        type: '',
        icon: ''
    });

    useEffect(() => {
        if (isOpenConf) {
            document.body.style.overflow = "hidden";
            setTimeout(() => {
                document.body.style.position = "fixed";
                document.body.style.width = "100%";
                document.body.style.height = "100%";
            }, 10);

            console.log(window.pageYOffset);
        } else {
            document.body.style.overflow = "unset";
            document.body.style.position = "unset";
            document.body.style.width = "unset";
            document.body.style.height = "unset";
            document.body.style.top = `unset`;
        }
    }, [isOpenConf]);

    return (
        <>
            <WaiterConf isOpen={isOpenConf} setIsOpen={setIsOpenConf} />
            <Rules isRulesOpen={isRulesOpen} setRulesOpen={setRulesOpen} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className='catalog'>
                <TangLogo />
                <div className='catalog__title-menu__box'>
                    {catalog.map((item, i) => {
                        return (
                            <Link to={`/catalog/${item.id}`} key={item.id} className='catalog__title-menu'>{item.title}</Link>
                        )
                    })
                    }
                    <p onClick={() => setRulesOpen(true)} className='catalog__title-menu catalog__title-menu_rules'>правила</p>
                </div>
                <a target="_blank" className='catalog__bonus-card' href='https://cards.premiumbonus.su/tngmskPyatnickaya715/login'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M2 8H20" stroke="#DEB75D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8.57 12H6" stroke="#DEB75D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M17 18H5C3.343 18 2 16.657 2 15V7C2 5.343 3.343 4 5 4H17C18.657 4 20 5.343 20 7V15C20 16.657 18.657 18 17 18Z" stroke="#DEB75D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    Получить бонусную карту <span className=''>→</span></a>
                <div className='catalog__conf-and-term'>
                    <ServiceButtonsBlock isOpenConf={isOpenConf} setIsOpenConf={setIsOpenConf} />
                    <Terms />
                </div>
            </motion.div>
        </>
    );
}

export default Catalog