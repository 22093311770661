export const TangLogo = ({ }) => {
    return (
        <svg className='loading__tang-logo' xmlns="http://www.w3.org/2000/svg" width="234" height="68" viewBox="0 0 234 68" fill="none">
            <path d="M44.3392 49.1577V16.85L66.2861 39.8038V18.8429H70.97V50.9761L49.0232 28.0236V49.1577H44.3392Z" fill="white" />
            <path d="M104.798 33.3931H117.289V34.346C117.289 37.2917 116.943 40.6696 114.556 44.0474C112.951 46.2993 109.525 49.763 103.15 49.763C98.2913 49.763 94.8223 47.9446 92.2189 45.3465C88.8796 42.0123 87.6223 38.0714 87.6223 34.042C87.6223 30.0153 88.967 25.9872 91.9595 22.8697C95.5595 19.102 100.026 18.2364 103.409 18.2364C107.964 18.2364 111 19.969 112.648 21.1372C113.992 22.09 115.076 23.1301 115.988 24.4292L112.691 27.5467C111.737 26.1617 108.918 22.5658 103.409 22.5658C96.9902 22.5658 92.4796 27.4608 92.4796 34.042C92.4796 40.7554 97.3807 45.4324 103.28 45.4324C108.093 45.4324 111.607 42.1417 112.345 37.7238H104.798V33.3931Z" fill="white" />
            <path d="M26.1967 23.6157V19.285H0V23.6157H10.7564V49.7633H26.1967V45.4327H15.4403V23.6157H26.1967Z" fill="white" />
            <path d="M140.924 68H137.605V0H140.924V68Z" fill="white" />
            <path d="M164.912 20.3401V27.4366H162.276V20.3401H161.24V18.0964H162.276V14.9039H164.912V18.0964H165.948V20.3401H164.912Z" fill="white" />
            <path d="M170.23 22.7559C170.23 24.2445 171.309 25.4086 172.821 25.4086C174.333 25.4086 175.413 24.2445 175.413 22.7559C175.413 21.2674 174.333 20.1237 172.821 20.1237C171.309 20.1237 170.23 21.2674 170.23 22.7559ZM175.155 18.0967H177.79V27.4369H175.155V26.2292C174.204 27.5869 172.95 27.7382 172.28 27.7382C169.408 27.7382 167.506 25.4945 167.506 22.7559C167.506 19.9956 169.387 17.7955 172.152 17.7955C172.864 17.7955 174.268 17.925 175.155 19.3045V18.0967Z" fill="white" />
            <path d="M180.132 18.0964H182.768V19.3041C183.265 18.6144 184.151 17.7951 185.663 17.7951C186.485 17.7951 187.694 18.0323 188.427 18.9375C189.077 19.7363 189.163 20.6632 189.163 21.5697V27.4366H186.528V22.3453C186.528 21.8287 186.505 21.0081 186.03 20.5119C185.62 20.0811 185.058 20.0375 184.799 20.0375C184.108 20.0375 183.567 20.2747 183.179 20.8568C182.79 21.462 182.768 22.1313 182.768 22.6697V27.4366H180.132V18.0964Z" fill="white" />
            <path d="M193.706 22.7559C193.706 24.2445 194.786 25.4086 196.298 25.4086C197.81 25.4086 198.89 24.2445 198.89 22.7559C198.89 21.2674 197.81 20.1237 196.298 20.1237C194.786 20.1237 193.706 21.2674 193.706 22.7559ZM198.587 18.0967H201.222V27.0921C201.222 28.2562 201.245 29.5716 200.165 30.823C199.237 31.8794 197.854 32.4628 196.255 32.4628C193.619 32.4628 191.545 30.9743 191.22 28.537H194.072C194.267 29.5934 195.11 30.2191 196.276 30.2191C198.545 30.2191 198.587 28.1267 198.587 27.5869V26.1856C197.68 27.5651 196.407 27.7382 195.715 27.7382C192.864 27.7382 190.983 25.4945 190.983 22.7559C190.983 20.1237 192.733 17.7955 195.65 17.7955C197.401 17.7955 198.22 18.7442 198.587 19.3045V18.0967Z" fill="white" />
            <path d="M206.214 27.4359H203.578V18.0956H206.214V27.4359ZM204.895 12.5749C205.761 12.5749 206.472 13.2865 206.472 14.1494C206.472 15.0122 205.761 15.7252 204.895 15.7252C204.031 15.7252 203.319 15.0122 203.319 14.1494C203.319 13.2865 204.031 12.5749 204.895 12.5749Z" fill="white" />
            <path d="M215.227 21.5265C215.013 20.2547 213.975 19.6495 213.002 19.6495C212.031 19.6495 211.014 20.2751 210.8 21.5265H215.227ZM217.648 24.8266C217.367 25.5164 216.307 27.7383 213.046 27.7383C211.533 27.7383 210.389 27.3076 209.459 26.4229C208.423 25.4523 207.99 24.2446 207.99 22.7779C207.99 20.9226 208.747 19.779 209.417 19.111C210.519 18.0328 211.814 17.7956 212.939 17.7956C214.838 17.7956 215.941 18.5494 216.588 19.3264C217.582 20.5123 217.713 21.9791 217.713 22.9932V23.2086H210.714C210.714 23.7689 210.866 24.3741 211.166 24.783C211.447 25.1715 212.031 25.6677 213.046 25.6677C214.04 25.6677 214.796 25.1933 215.227 24.4382L217.648 24.8266Z" fill="white" />
            <path d="M219.575 18.0964H222.212V19.4991C222.622 18.7003 223.184 18.3118 223.379 18.2041C223.788 17.9465 224.307 17.7951 224.934 17.7951C225.387 17.7951 225.754 17.9028 226.057 18.0105L225.711 20.4696C225.367 20.2324 225.02 20.1234 224.479 20.1234C223.984 20.1234 223.248 20.2324 222.729 20.8799C222.233 21.5042 222.212 22.303 222.212 22.9505V27.4366H219.575V18.0964Z" fill="white" />
            <path d="M232.229 20.3182C231.603 19.7362 230.975 19.6925 230.695 19.6925C230.07 19.6925 229.681 19.9951 229.681 20.4477C229.681 20.6849 229.788 21.0516 230.521 21.2888L231.149 21.4837C231.883 21.7209 232.986 22.0876 233.526 22.8209C233.805 23.2094 234 23.7697 234 24.3735C234 25.2146 233.719 26.0556 232.962 26.7468C232.206 27.4365 231.301 27.7391 230.156 27.7391C228.21 27.7391 227.108 26.8108 226.526 26.1851L227.907 24.5889C228.426 25.1928 229.204 25.6685 229.981 25.6685C230.717 25.6685 231.278 25.3005 231.278 24.653C231.278 24.0709 230.803 23.8337 230.459 23.7056L229.853 23.4889C229.182 23.2517 228.405 22.9504 227.844 22.3684C227.412 21.9144 227.13 21.3324 227.13 20.5772C227.13 19.6707 227.563 18.9169 228.102 18.463C228.838 17.8809 229.788 17.795 230.545 17.795C231.235 17.795 232.337 17.8809 233.526 18.786L232.229 20.3182Z" fill="white" />
            <path d="M164.912 49.0184H162.276V32.8625H164.912V49.0184Z" fill="white" />
            <path d="M174.732 44.3594C174.732 42.8927 173.61 41.7054 172.098 41.7054C170.585 41.7054 169.463 42.8927 169.463 44.3594C169.463 45.7825 170.563 46.9903 172.098 46.9903C173.631 46.9903 174.732 45.7825 174.732 44.3594ZM175.878 40.6926C176.806 41.5336 177.456 42.8273 177.456 44.3594C177.456 45.8684 176.806 47.1634 175.878 48.0045C175.079 48.7378 173.868 49.3199 172.098 49.3199C170.326 49.3199 169.116 48.7378 168.317 48.0045C167.389 47.1634 166.739 45.8684 166.739 44.3594C166.739 42.8273 167.389 41.5336 168.317 40.6926C169.116 39.9592 170.326 39.3772 172.098 39.3772C173.868 39.3772 175.079 39.9592 175.878 40.6926Z" fill="white" />
            <path d="M181.886 39.678V44.9629C181.886 45.459 181.928 46.1283 182.447 46.6027C182.771 46.8822 183.29 47.0771 183.786 47.0771C184.283 47.0771 184.802 46.8822 185.126 46.6027C185.644 46.1283 185.687 45.459 185.687 44.9629V39.678H188.322V45.3936C188.322 46.2347 188.215 47.3579 187.179 48.263C186.313 49.0182 184.93 49.3208 183.786 49.3208C182.641 49.3208 181.258 49.0182 180.394 48.263C179.359 47.3579 179.25 46.2347 179.25 45.3936V39.678H181.886Z" fill="white" />
            <path d="M190.639 39.678H193.275V40.8857C193.772 40.196 194.658 39.3767 196.169 39.3767C196.991 39.3767 198.201 39.6139 198.934 40.519C199.583 41.3178 199.669 42.2448 199.669 43.1512V49.0182H197.035V43.9269C197.035 43.4102 197.011 42.5896 196.536 42.0934C196.127 41.6627 195.564 41.6191 195.305 41.6191C194.614 41.6191 194.074 41.8563 193.686 42.4383C193.297 43.0436 193.275 43.7129 193.275 44.2513V49.0182H190.639V39.678Z" fill="white" />
            <path d="M204.213 44.3375C204.213 45.826 205.292 46.9902 206.804 46.9902C208.316 46.9902 209.396 45.826 209.396 44.3375C209.396 42.849 208.316 41.7053 206.804 41.7053C205.292 41.7053 204.213 42.849 204.213 44.3375ZM209.093 39.6783H211.728V48.6723C211.728 49.8377 211.751 51.1532 210.671 52.4045C209.743 53.461 208.36 54.0444 206.761 54.0444C204.125 54.0444 202.051 52.5558 201.727 50.1186H204.578C204.774 51.175 205.616 51.8007 206.782 51.8007C209.051 51.8007 209.093 49.7083 209.093 49.1684V47.7671C208.187 49.1466 206.913 49.3198 206.221 49.3198C203.37 49.3198 201.49 47.076 201.49 44.3375C201.49 41.7053 203.241 39.377 206.157 39.377C207.907 39.377 208.726 40.3258 209.093 40.886V39.6783Z" fill="white" />
            <path d="M220.807 43.1081C220.592 41.8363 219.555 41.231 218.581 41.231C217.611 41.231 216.594 41.8567 216.379 43.1081H220.807ZM223.227 46.4082C222.946 47.098 221.887 49.3199 218.625 49.3199C217.112 49.3199 215.968 48.8891 215.039 48.0045C214.003 47.0339 213.57 45.8262 213.57 44.3594C213.57 42.5042 214.326 41.3605 214.996 40.6926C216.098 39.6144 217.394 39.3772 218.519 39.3772C220.418 39.3772 221.521 40.131 222.168 40.908C223.162 42.0939 223.293 43.5606 223.293 44.5748V44.7902H216.293C216.293 45.3504 216.445 45.9557 216.745 46.3646C217.026 46.7531 217.611 47.2493 218.625 47.2493C219.619 47.2493 220.375 46.7749 220.807 46.0197L223.227 46.4082Z" fill="white" />
        </svg>
    )
}
export const TangLogoHeader = ({ }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="62" height="18" viewBox="0 0 62 18" fill="none">
            <path d="M23.438 17.0409V0L35.0393 12.1071V1.05117H37.5153V18L25.914 5.89359V17.0409H23.438Z" fill="white" />
            <path d="M55.3969 8.72578H62V9.22836C62 10.7821 61.8167 12.5638 60.5553 14.3454C59.7066 15.5332 57.896 17.3602 54.5259 17.3602C51.9575 17.3602 50.1238 16.401 48.7476 15.0306C46.9824 13.272 46.3178 11.1934 46.3178 9.06802C46.3178 6.94412 47.0286 4.81949 48.6105 3.17515C50.5135 1.18785 52.8747 0.731292 54.663 0.731292C57.0704 0.731292 58.6754 1.64513 59.5464 2.26131C60.2572 2.76389 60.8302 3.31248 61.3123 3.99768L59.5695 5.64202C59.065 4.91152 57.5748 3.01482 54.663 3.01482C51.2698 3.01482 48.8854 5.59672 48.8854 9.06802C48.8854 12.6091 51.4762 15.0759 54.5944 15.0759C57.139 15.0759 58.9965 13.3403 59.3862 11.01H55.3969V8.72578Z" fill="white" />
            <path d="M13.8478 3.56861V1.28436H0V3.56861H5.68589V17.3603H13.8478V15.0761H8.16188V3.56861H13.8478Z" fill="white" />
        </svg>
    )
}