import { BARMAN_REQUEST_TYPE, BILL_CATALOG_BUTTON, CHECK_REQUEST_TYPE, CURRENCY_MAP, HOOKAH_REQUEST_TYPE, MASTER_CATALOG_BUTTON, WAITER_CATALOG_BUTTON } from "./constants";

// export const sendMetriс = ({ type, value, counters }) => {

//     // const COUNTERS_EXEMPLE = [
//     //     {
//     //         id: '94194405',
//     //     },
//     // ]
//     if (!counters || counters.length === 0) return
//     for (let index = 0; index < counters.length; index++) {
//         const counter = counters[index];
//         window.ym(counter.id, type, value)
//     }
// }

// export function getRandomId() {
//     const ObjectId = (
//         m = Math,
//         d = Date,
//         h = 16,
//         s = (s) => m.floor(s).toString(h)
//     ) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));
//     return ObjectId();
// }


export const getFinalPrice = (product, promo) => {
    const price_with_discount = product.discount ? (Number(product.price) - Number(product.price) / 100 * Number(product.discount)) : Number(product.price)
    if (promo && promo.isValid) {
        return price_with_discount - (price_with_discount / 100 * Number(promo.discount))
    } else {
        return price_with_discount
    }
}


export const buildCategoryPath = (categories, currentIndex, isTranslitName = true) => {
    return categories.slice(0, currentIndex + 1).map(cat => isTranslitName ? cat.translit_name : cat).join('/');
};

export function parsePrice(string, currency) {
    return Number(string) ? `${Number(string).toLocaleString('us')} ${CURRENCY_MAP[currency.toUpperCase()] ? CURRENCY_MAP[currency.toUpperCase()] : ''}` : `0 ${CURRENCY_MAP[currency.toUpperCase()]}`
}


export function parseParameterValue(parameter) {
    switch (parameter.type) {
        case 'boolean':
            return parameter.value ? 'Да' : 'Нет'

        case 'number':
            return Number(parameter.value).toLocaleString()


        default:
            return parameter.value
    }
}

export function getCorrectWordForm(count) {
    return 'шт.'
    // let lastDigit = count % 10;
    // if (count >= 11 && count <= 19) {
    //     return 'товаров';
    // }
    // switch (lastDigit) {
    //     case 1:
    //         return 'товар';
    //     case 2:
    //     case 3:
    //     case 4:
    //         return 'товара';
    //     default:
    //         return 'товаров';
    // }
}

export function compareOptions(array1, array2) {
    // Initialize objects to store matches, unique elements from each array, and differences
    const result = {
        matches: [],
        uniqueInArray1: [],
        uniqueInArray2: [],
        differences: [] // Objects with the same name but different values
    };

    // Convert array2 into a Map for efficient lookup by name
    const mapArray2 = new Map(array2.map(item => [item.name, item]));

    // Compare elements of array1 to array2
    array1.forEach(item1 => {
        const item2 = mapArray2.get(item1.name);
        if (item2) {
            if (item1.value === item2.value) {
                result.matches.push(item1);
            } else {
                result.differences.push({ name: item1.name, value1: item1.value, value2: item2.value });
            }
            mapArray2.delete(item1.name); // Remove matched item to find unique items in array2 later
        } else {
            result.uniqueInArray1.push(item1);
        }
    });

    // Remaining items in mapArray2 are unique to array2
    result.uniqueInArray2 = Array.from(mapArray2.values());

    return result;
}

export function setCookieWithShop(cookie_name, shop_id, value, daysToLive) {
    const name = cookie_name + shop_id;
    let expires = "";
    if (daysToLive) {
        const date = new Date();
        date.setTime(date.getTime() + (daysToLive * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookieWithShop(name, shop_id) {
    const nameEQ = name + shop_id + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function getRandomNumber(multiplier) {
    return Math.floor(Math.random() * multiplier);
  }
  export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // Максимум не включается, минимум включается
  }

export function parseRequestType(type) {
    switch (type) {
        case WAITER_CATALOG_BUTTON.type: return BARMAN_REQUEST_TYPE;
        case MASTER_CATALOG_BUTTON.type: return HOOKAH_REQUEST_TYPE;
        case BILL_CATALOG_BUTTON.type: return CHECK_REQUEST_TYPE;
        default: break;
    }
}
